<template>
    <div>
        <div class="coords-admin">
            <h2>Coordenadas</h2>
            <div class="coords-admin-menu" @click.prevent="open = !open">
                <img src="../assets/menu.png" />
            </div>
        </div>
        <transition name="slide-fade">
            <div class="coords-admin-content" v-if="open">
                <ul>
                    <li @click.prevent="getSair">
                        <a href="#">Sair</a>
                    </li>
                </ul>
            </div>
        </transition>

        <div class="nota">
            <a :href="`https://coordsgo.com/coords/${$route.params.id}`" target="_black">https://coordsgo.com/coords/{{
            $route.params.id }}</a>
        </div>
        <form @submit.prevent="salveCoords">
            <input class="input-x" type="text" placeholder="Coordenadas" v-model="mensagem.coord" />
            <br />
            <div class="content-coords">
                <button class="btn-x">Enviar</button>
                <div class="paste-content" v-if="user.id != 0">
                    <a class="btn-x" href="#" @click.prevent="checkClipboard">
                        <img class="paste" src="../assets/paste_icon.png" />
                    </a>
                </div>
                <div class="paste-content" v-else>
                    <a class="btn-x" href="#" @click.prevent="checkClipboardCoords">
                        <img class="paste" src="../assets/paste_icon.png" />
                    </a>
                    <a class="btn-x" href="#" @click.prevent="checkClipboardMesclar">
                        <img class="paste" src="../assets/paste_icon.png" />
                    </a>
                </div>
            </div>
        </form>
        <ul class="list-group">
            <li class="list-group-item" v-for="(m, index) in mensagens" :key="index">
                <small>{{ m.datacad | corrigeData }}</small>{{ m.coords }}
                <button class="remove" @click="removeCoords(m.key)">x</button>
            </li>
        </ul>

    </div>
</template>

<script>
import CoordsDataService from '../services/services'
import moment from 'moment'
import { onValue } from "firebase/database"
import { Clipboard } from '@capacitor/clipboard'

export default {
    data: () => ({
        mensagem: {
            coord: '',
            datacad: ''
        },
        mensagens: [],
        currentCoords: null,
        currentIndex: -1,
        open: false,
        user: {},
        mesclar: []
    }),
    filters: {
        corrigeData(x) {
            return moment(x).format('DD/MM/YYYY HH:mm:ss')
        }
    },
    methods: {
        setReplace(value) {
            return value
                .replaceAll(' :genderless: : hidden', ' ')
                .replaceAll(':female: : hidden', '♀')
                .replaceAll(':male: : hidden', '♂')
                .replaceAll(')', ')\n')
                .replaceAll(' :idv:', ' IV')
                .replaceAll('.00', '')
                .replaceAll(':cdp:', 'CP')
                .replaceAll(':ldvl:', 'LV')
                .replaceAll(/:moveset: .+/g, '')
                .replaceAll(' @Max Hundo', '')
                .replaceAll('@High Level Hundo Hunter', '')
                .replaceAll('Articuno Bot', '')
                .replaceAll('BOT', '')
                .replaceAll(/ — Hoje.+/g, ' ')
        },
        async checkClipboard() {
            const { value } = await Clipboard.read()
            let nvalue = this.setReplace(value)
            nvalue = nvalue.toString().replaceAll('\n', '')
            Clipboard.write({
                string: nvalue
            })
            this.mensagem.coord = value

        },
        async checkClipboardCoords() {
            const { value } = await Clipboard.read()
            let nvalue = this.setReplace(value)
            nvalue = nvalue.toString().replaceAll('\n', '')
            this.mesclar.push(nvalue)
            this.mensagem.coord = nvalue
        },
        async checkClipboardMesclar() {

        },
        create_UUID() {
            var dt = new Date().getTime()
            var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = (dt + Math.random() * 16) % 16 | 0
                dt = Math.floor(dt / 16)
                return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16)
            })
            return uuid
        },
        async salveCoords() {
            if (!this.mensagem.coord) {
                return
            }
            this.mesclar = []
            const data = {
                coords: this.mensagem.coord,
                datacad: moment().format('YYYY-MM-DD HH:mm:ss'),
                copy: false,
                user: this.$route.params.id,
                uuid: this.create_UUID()
            }
            await CoordsDataService.create(data)
            this.mensagem.coord = ''
            this.mensagem.datacad = ''
        },
        onDataChange(items) {
            let _mensagens = []

            items.forEach(item => {
                let key = item[0]
                let data = item[1]
                _mensagens.push({
                    key: key,
                    coords: data.coords,
                    datacad: data.datacad,
                    copy: false,
                    user: data.user
                })
            })
            //_mensagens = _mensagens.reverse()
            this.mensagens = _mensagens
                .filter(i => i.user === this.$route.params.id)
                .reverse()
        },

        refreshList() {
            this.currentCoords = null
            this.currentIndex = -1
        },

        removeCoords(id) {
            CoordsDataService.delete(id)
                .then(() => {
                    this.refreshList()
                })
                .catch(e => {
                    console.log(e)
                })
        },

        removeAlCoords() {
            CoordsDataService.deleteAll()
                .then(() => {
                    this.refreshList()
                })
                .catch(e => {
                    console.log(e)
                })
        },

        getSair() {
            localStorage.removeItem('_l')
            this.$router.push('/login')
        }
    },
    async mounted() {
        const x = await CoordsDataService.getAll()
        onValue(x, (snapshot) => {
            const data = snapshot.val()
            this.onDataChange(Object.entries(data))
        })
        if (localStorage.getItem('_l')) {
            this.user = JSON.parse(localStorage.getItem('_l'))
        }
    },
    beforeDestroy() {
        CoordsDataService.off()
    }
}
</script>

<style lang="scss" scoped>
.coords-admin {

    display: flex;
    justify-content: space-between;

    &-menu {
        margin-top: 10px;
        margin-bottom: 10px;
        height: 40px;
        width: 40px;
        background-color: #673bb7;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 30px;
        }
    }

}

.coords-admin-content {
    background-color: #fff;
    background-color: #ffffffde;
    position: absolute;
    z-index: 1;
    right: 0;
    width: 100%;
    padding-bottom: 20px;

    ul {
        background-color: #fff;
        width: 350px;
        margin-block-start: 0;
        padding-inline-start: 0;
        margin: 0 25px;
        list-style: none;

        li {
            background-color: #673bb7;
            padding: 10px;

            a {
                color: #fff;
                font-size: 14px;
                text-decoration: none;
            }
        }
    }
}

.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */
    {
    transform: translateX(10px);
    opacity: 0;
}

.paste {
    height: 25px;
}

.paste-content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.content-coords {
    display: flex;
    justify-content: end;
}

h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0;
}

.input-x {
    border: 1px solid #673bb7;
    border-radius: 5px;
    height: 30px;
    width: 90%;
    padding: 5px;
}

.btn-x {
    background-color: #673bb7;
    color: #fff;
    margin: 10px;
    padding: 10px 20px;
    border: 1px solid;
}

.list-group {
    list-style: none;
    margin: 0;
    padding: 10px;
}

.list-group-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 13px;

    small {
        font-size: 11px;
    }

    .remove {
        background-color: #b73b43;
        border: 1px solid #b73b43;
        color: #fff;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.nota {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: #2c3e50;
    border: 1px solid #673bb7;
    background: #ffc107;
    padding: 5px;
    border-radius: 5px;
    font-weight: bold;
    top: 0;
    height: 48px;
    z-index: 2;
    margin-bottom: 15px;

    a {
        color: #2c3e50;
        text-decoration: none;
    }

    i {
        margin-right: 10px;
    }
}
</style>
